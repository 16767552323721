import React from 'react';

import { useTheme } from '../../context/ThemeContext';

interface CSharpLogoProps {}

export const CSharpLogo = ({ ...props }: CSharpLogoProps) => {
	const { activeTheme } = useTheme();
	return (
		<>
			<div className="logo-container">
				<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M54.1406 5.07813L87.8203 24.0078C90.3984 25.4531 92 28.1641 92 31.0703V68.9297C92 71.8359 90.3984 74.5469 87.8203 75.9922L54.1406 94.9219C52.8594 95.6406 51.4297 96 50 96C48.5703 96 47.1406 95.6406 45.8594 94.9219L12.1797 75.9922C9.60156 74.5469 8 71.8359 8 68.9297V31.0703C8 28.1641 9.60156 25.4531 12.1797 24.0078L45.8594 5.07813C48.4141 3.64844 51.5859 3.64844 54.1406 5.07813ZM26 50C26 63.2344 36.7656 74 50 74C57.5625 74 64.5469 70.4922 69.0859 64.5156L60.3203 59.4453C57.6875 62.3281 53.9453 64 50 64C42.2812 64 36 57.7188 36 50C36 42.2812 42.2812 36 50 36C53.9453 36 57.6875 37.6719 60.3203 40.5547L69.0859 35.4844C64.5469 29.5078 57.5625 26 50 26C36.7656 26 26 36.7656 26 50ZM65 45.4999C65 44.6715 65.6716 43.9999 66.5 43.9999H69.2984L69.7339 40.8504C69.8444 40.0508 70.5822 39.4922 71.3818 39.6028C72.1814 39.7133 72.74 40.4511 72.6294 41.2507L72.2493 43.9999H76.2714L76.7725 40.376C76.883 39.5764 77.6208 39.0178 78.4204 39.1284C79.22 39.2389 79.7785 39.9767 79.668 40.7763L79.2223 43.9999H81.5C82.3284 43.9999 83 44.6715 83 45.4999C83 46.3284 82.3284 46.9999 81.5 46.9999H78.8075L78.1162 51.9999H80.5C81.3284 51.9999 82 52.6715 82 53.4999C82 54.3284 81.3284 54.9999 80.5 54.9999H77.7015L77.266 58.1495C77.1554 58.9491 76.4176 59.5077 75.6181 59.3971C74.8185 59.2866 74.2599 58.5488 74.3705 57.7492L74.7506 54.9999H70.7285L70.2274 58.6239C70.1169 59.4235 69.3791 59.982 68.5795 59.8715C67.7799 59.7609 67.2213 59.0231 67.3319 58.2236L67.7776 54.9999H65.5C64.6716 54.9999 64 54.3284 64 53.4999C64 52.6715 64.6716 51.9999 65.5 51.9999H68.1924L68.8837 46.9999H66.5C65.6716 46.9999 65 46.3284 65 45.4999ZM71.8345 46.9999L71.1432 51.9999H75.1653L75.8566 46.9999H71.8345Z"
						fill={activeTheme === 'light' ? '#2F2F3A' : '#817ed9'}
					/>
				</svg>

				<p style={{ color: activeTheme === 'light' ? '#2F2F3A' : '#817ed9' }}>C#</p>
			</div>
		</>
	);
};

export default CSharpLogo;
